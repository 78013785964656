<script setup lang="ts">
import { type FunctionalComponent } from 'vue'

import { Box, type BoxProps } from '@/components'

import { sprinkles, type Sprinkles } from '@/styles/sprinkles.css'

export interface IconProps extends BoxProps {
  fill?: Sprinkles['color']

  /* Lucide icon component instance */

  icon: FunctionalComponent

  /* Size of the icon. */

  size?: number

  /* Stroke with of the icon */

  strokeWidth?: number
}

const { fill, icon, size = 16, strokeWidth = 1.5, ...rest } = defineProps<IconProps>()
</script>

<template>
  <Box v-bind="rest" as-child>
    <component :is="icon" :class="sprinkles({ fill })" :size="size" :stroke-width="strokeWidth" />
  </Box>
</template>
