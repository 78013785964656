<script setup lang="ts">
import { Box, type BoxProps } from '../Box'

import { sprinkles, type Sprinkles } from '@/styles/sprinkles.css'

export interface FlexProps extends BoxProps {
  /** Aligns the box within its container. */

  alignItems?: Sprinkles['alignItems']

  /** Direction of the flex container. */

  direction?: Sprinkles['flexDirection']

  /** Gap between the flex items. */

  gap?: Sprinkles['gap']

  /** Justifies the con flex container. */

  justifyContent?: Sprinkles['justifyContent']

  /** Allows the flex container to wrap. */

  wrap?: Sprinkles['flexWrap']
}

const { alignItems, direction, display, gap, justifyContent, wrap, ...rest } =
  defineProps<FlexProps>()
</script>

<template>
  <Box
    v-bind="rest"
    :display="display ?? 'flex'"
    :class="
      sprinkles({
        alignItems,
        flexDirection: direction,
        display: display ?? 'flex',
        flexWrap: wrap,
        gap,
        justifyContent
      })
    "
  >
    <slot />
  </Box>
</template>
