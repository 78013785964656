<script setup lang="ts">
import { provide, ref } from 'vue'

import { ToastProvider, ToastViewport } from 'reka-ui'

import { Flex, type NotificationProps } from '@/components'

import * as styles from './Provider.css'

import { Notifications } from '@/lib/symbols'

const notifications = ref<Array<NotificationProps & { created_at: Date }>>([])

export interface NotificationProviderProps {}

provide(Notifications, notifications)
</script>

<template>
  <ToastProvider>
    <slot />

    <Flex
      as-child
      :class="styles.viewPort"
      direction="column"
      :gap="20"
      :padding-block="100"
      :padding-inline="100"
      position="fixed"
    >
      <ToastViewport label="Notifications ({hotkey})" />
    </Flex>
  </ToastProvider>
</template>
