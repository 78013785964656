import type { Router } from 'vue-router'

import * as Sentry from '@sentry/vue'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setupSentry(app: any, router: Router) {
  // releases follow a semVer like pattern, prefixed with release/
  const releaseTag = import.meta.env.VITE_SENTRY_RELEASE
  const release = releaseTag ? releaseTag.replace(/^release\//, '') : undefined

  if (release) {
    console.log('EMT Release:', release)
  }

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({
        router
      }),
      Sentry.replayIntegration()
    ],

    environment: import.meta.env.VITE_SENTRY_ENV,

    release: release ? 'emt-app@' + release : undefined,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SR),

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/emt\.test\.wienfluss\.net/],

    // Capture Replay for VITE_SENTRY_REPLAY_SR (10%) of all sessions,
    // plus for VITE_SENTRY_REPLAY_ON_ERROR_SR (100%) of sessions with an error
    replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAY_SR),
    replaysOnErrorSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAY_ON_ERROR_SR)
  })
}
