<script setup lang="ts">
import { Box, type BoxProps } from '../Box'

import { sprinkles, type Sprinkles } from '@/styles/sprinkles.css'

export interface TextProps extends BoxProps {
  /** Text alignment */

  align?: Sprinkles['textAlign']

  /** Font family */

  family?: Sprinkles['fontFamily']

  /* Font feature settings */

  fontFeatureSettings?: Sprinkles['fontFeatureSettings']

  /** Font style */

  fontStyle?: Sprinkles['fontStyle']

  /* Font variant numeric */

  fontVariantNumeric?: Sprinkles['fontVariantNumeric']

  /* Font variant caps */

  fontVariantCaps?: Sprinkles['fontVariantCaps']

  /** Font size */

  size?: Sprinkles['fontSize']

  /** Text decoration */

  textDecoration?: Sprinkles['textDecoration']

  /* Text overflow */

  textOverflow?: Sprinkles['textOverflow']

  /** Font weight */

  weight?: Sprinkles['fontWeight']

  /** White space wrapping */

  whiteSpace?: Sprinkles['whiteSpace']
}

const {
  align,
  textDecoration,
  textOverflow,
  family,
  size,
  fontFeatureSettings,
  fontStyle,
  fontVariantCaps,
  fontVariantNumeric,
  weight,
  whiteSpace,
  ...rest
} = defineProps<TextProps>()
</script>

<template>
  <Box
    v-bind="rest"
    :class="
      sprinkles({
        textAlign: align,
        fontFamily: family,
        fontFeatureSettings,
        fontSize: size,
        fontStyle,
        fontWeight: weight,
        fontVariantCaps,
        fontVariantNumeric,
        textDecoration,
        textOverflow,
        whiteSpace
      })
    "
  >
    <slot />
  </Box>
</template>
