import { type ComponentPublicInstance, type ComputedRef, type InjectionKey, type Ref } from 'vue'

import { type FieldProps, type NotificationProps } from '@/components'

import type { GetCycleAssessment, GetCycleAssessmentNavigation } from '@/contracts/cycles'
import type { User as TUser } from '@/contracts/user'

import { type BreadcrumbsProps } from '@/components/Breadcrumbs'
import {
  type AssessmentGuidance as TAssessmentGuidance,
  type GetTranslation
} from '@/contracts/assessment-guidances'
import { type Branch as TBranch, type GetFeatures as GetBranchFeatures } from '@/contracts/branches'
import {
  type Catalog as TCatalog,
  type CatalogTranslation as TCatalogTranslation
} from '@/contracts/catalogs'
import {
  type GetCycle as TMunicipalityCycle,
  type Cycles as TMunicipalityCycles,
  type Municipality as TMunicipality,
  type GetFeatures
} from '@/contracts/municipalities'
import { type Profile } from '@/contracts/profile'
import { type UserRole as TUserRole } from '@/contracts/roles'

export const AuthenticatedUser: InjectionKey<Readonly<ComputedRef<Profile.Response['data']>>> =
  Symbol('AuthenticatedUser')

export const Cycles: InjectionKey<{
  cycles: Ref<TMunicipalityCycles.Response['data'] | undefined>
  isPending: Ref<boolean>
}> = Symbol('Cycles')

export const Field: InjectionKey<ComputedRef<FieldProps>> = Symbol('Field')

export const FormWarnings: InjectionKey<{ warnings: Ref<Record<string, string | undefined>> }> =
  Symbol('FormWarnings')

export const FormLayout: InjectionKey<{
  headerRef: ComponentPublicInstance | null
  titleRef: ComponentPublicInstance | null
}> = Symbol('FormLayout')

export const Notifications: InjectionKey<Ref<Array<NotificationProps>>> = Symbol('Notifications')

export const Branch: InjectionKey<{
  branch: Ref<TBranch | undefined>
  features: Ref<GetBranchFeatures.Response['data'] | undefined>
  isPending: Ref<boolean>
}> = Symbol('Branch')

export const Cycle: InjectionKey<{
  cycle: Ref<TMunicipalityCycle.Response['data'] | undefined>
  isPending: Ref<boolean>
}> = Symbol('Cycle')

export const Assessment: InjectionKey<{
  assessment: Ref<GetCycleAssessment.Response['data'] | undefined>
  assessmentGuidance: Ref<GetTranslation.Response['data'] | undefined>
  assessmentNavigation: Ref<GetCycleAssessmentNavigation.Response['data'] | undefined>
  isPending: Ref<boolean>
}> = Symbol('Assessment')

export const Municipality: InjectionKey<{
  features: Ref<GetFeatures.Response['data'] | undefined>
  municipality: Ref<TMunicipality | undefined>
  isPending: ComputedRef<boolean>
}> = Symbol('Municipality')

export const Breadcrumbs: InjectionKey<ComputedRef<BreadcrumbsProps['items']>> =
  Symbol('Breadcrumbs')

export interface UserRoleContext {
  // The actual role that the user has on the current branch, municipality or cycle. This role will
  // most often be identical to defaultRole, but can be changed by the user using manual or virtual
  // role switching.

  role: TUserRole

  // The role a user might have chosen by using manual role switching. This will most often be the
  // same as the defaultRole. Virtual role switching is using the manual role to determine to which
  // role the user can switch.

  manualRole: TUserRole

  // The default role is determined by the automatic role negotiation on branches, municipalities or
  // cycles. We need to keep track of the default role to be able to reset the role to the default.

  defaultRole: TUserRole
}

export const UserRole: InjectionKey<Ref<UserRoleContext>> = Symbol('UserRole')

export const Catalog: InjectionKey<
  Readonly<{ catalog: Ref<TCatalog | undefined>; isPending: Ref<boolean> }>
> = Symbol('Catalog')

export const CatalogTranslation: InjectionKey<
  Readonly<{ catalogTranslation: Ref<TCatalogTranslation | undefined>; isPending: Ref<boolean> }>
> = Symbol('CatalogTranslation')

export const AssessmentGuidance: InjectionKey<
  Readonly<{ assessmentGuidance: Ref<TAssessmentGuidance | undefined>; isPending: Ref<boolean> }>
> = Symbol('AssessmentGuidance')

export const AssessmentGuidanceTranslation: InjectionKey<
  Readonly<{
    assessmentGuidanceTranslation: Ref<GetTranslation.Response['data'] | undefined>
    isPending: Ref<boolean>
  }>
> = Symbol('AssessmentGuidanceTranslation')

export const User: InjectionKey<
  Readonly<{
    user: Ref<TUser | undefined>
    isPending: Ref<boolean>
  }>
> = Symbol('User')
