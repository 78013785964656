import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import { createMongoAbility } from '@casl/ability'
import { abilitiesPlugin } from '@casl/vue'
import { VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query'
import { VueTolgee } from '@tolgee/vue'
import { createHead } from '@unhead/vue/client'

import App from '@/App.vue'

import { setupSentry } from './sentry-setup'
import { setupTolgee } from './tolgee-setup'

import { routes } from '@/router/router'

import '@/styles/global.css'

const app = createApp(App)
const router = createRouter({
  history: createWebHistory(),
  routes,

  // See https://router.vuejs.org/guide/advanced/scroll-behavior.html
  // Only force scroll-top if there's no hash in the URL, which is e.g.
  // important for inline-navigations in forms.
  scrollBehavior(to) {
    if (
      !to.hash &&
      (('scroll' in to.params && to.params.scroll !== 'false') || !('scroll' in to.params))
    ) {
      return { top: 0 }
    }
  }
})
const head = createHead()

if (!import.meta.env.DEV) {
  setupSentry(app, router)
}

const vueQueryPluginOptions: VueQueryPluginOptions = {
  // enable devtool integration
  enableDevtoolsV6Plugin: import.meta.env.DEV,

  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        // disable query retries
        retry: false
      }
    }
  }
}

app.use(abilitiesPlugin, createMongoAbility())
app.use(head)
app.use(router)
app.use(VueQueryPlugin, vueQueryPluginOptions)
app.use(VueTolgee, { tolgee: setupTolgee() })

app.mount('#app')
