import { FormatIcu } from '@tolgee/format-icu'
import { Tolgee, DevTools, type TolgeeOptions } from '@tolgee/vue'

import { languages } from '@/modules/languages'

const COMMON_CONFIG = {
  availableLanguages: languages.map((value) => value.key),
  defaultNs: 'app',
  fallbackLanguage: 'en',
  language: 'en',

  // Namespaces to load by default:
  // - `app` contains translations for the app itself
  // - `shared` contains translations shared across API and app
  ns: ['app', 'shared']
}

function createClient() {
  return Tolgee().use(FormatIcu())
}

function getEnvironmentConfig(mode: string): TolgeeOptions {
  if (mode === 'production' || mode === 'staging') {
    const translations = import.meta.glob('../tolgee-export/**/*.json')

    return {
      staticData: Object.entries(translations).reduce((acc, [path, loader]) => {
        const pathSplitted = path.split('/')
        const filename = pathSplitted.at(-1)
        const namespace = pathSplitted.at(-2)

        const language = filename!.split('.').at(0)

        // See https://docs.tolgee.io/js-sdk/providing-static-data#providing-data-using-dynamic-import
        // @ts-ignore this is fine
        acc[`${language}:${namespace}`] = loader

        return acc
      }, {})
    }
  }

  return {}
}

export function setupTolgee() {
  const client = createClient()
    .use(DevTools())
    .init({
      ...COMMON_CONFIG,
      ...getEnvironmentConfig(import.meta.env.MODE),
      apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
      apiKey: import.meta.env.VITE_TOLGEE_API_KEY
    })

  // the `preview` environment is being used for translating the app;
  // tolgee excludes the in-context translation by default in production;
  // more details: https://tolgee.io/js-sdk/in-context
  if (import.meta.env.MODE === 'preview') {
    import('@tolgee/web/tools').then((module) => {
      client.addPlugin(module.InContextTools())
    })
  }

  return client
}
