<script setup lang="ts">
import { Separator, type SeparatorProps } from 'reka-ui'

import { Box, type BoxProps } from '@/components'

import * as styles from './Divider.css'

export interface DividerProps extends BoxProps, SeparatorProps {}

const { orientation, ...rest } = defineProps<DividerProps>()
</script>

<template>
  <Box v-bind="rest" as-child>
    <Separator :orientation="orientation" :class="styles.divider({ orientation })" />
  </Box>
</template>
