<script setup lang="ts">
import { computed } from 'vue'

import { Check, TriangleAlert, X } from 'lucide-vue-next'
import { ToastClose, ToastRoot, ToastTitle, type ToastRootProps } from 'reka-ui'

import { Box, Divider, Flex, Icon, Text } from '@/components'

import * as styles from './Notification.css'

import { useNotifications } from '@/lib/useNotifications'

export interface NotificationProps {
  /** Unique ID of the notification */

  id: string

  /** Duration the notification is displayed */

  duration?: ToastRootProps['duration']

  /** Whether the notification is open */

  open?: ToastRootProps['open']

  /** Title of the notification */

  title: string

  /** Variant of the notification */

  variant?: 'positive' | 'negative'
}

const { close } = useNotifications()
const {
  duration = 5000,
  id,
  open = true,
  title,
  variant = 'positive',
  ...rest
} = defineProps<NotificationProps>()

const icon = computed(() => {
  switch (variant) {
    case 'negative':
      return TriangleAlert

    default:
      return Check
  }
})

const backgroundColor = computed(() => {
  switch (variant) {
    case 'negative':
      return 'error'
    default:
      return 'secondary200'
  }
})

function toggleNotification(open: NotificationProps['open']) {
  if (!open) {
    close(id)
  }
}
</script>

<template>
  <ToastRoot
    v-bind="rest"
    as-child
    :class="styles.notification"
    :duration="duration"
    :open="open"
    @update:open="toggleNotification"
  >
    <Flex
      align-items="stretch"
      :background-color="backgroundColor"
      :border-radius="10"
      color="tertiary50"
      :gap="40"
      :padding-block="30"
      :padding-inline="50"
    >
      <Flex align-items="center" :padding-block="10">
        <Icon aria-hidden :icon="icon" :size="20" :stroke-width="2" />
      </Flex>

      <Flex align-items="center" as-child>
        <ToastTitle as-child :class="styles.title">
          <Text as-child :size="40" color="tertiary50" line-height="tight">
            <span>
              {{ title }}
            </span>
          </Text>
        </ToastTitle>
      </Flex>

      <Box :class="styles.divider">
        <Divider color="tertiary50" height="full" orientation="vertical" />
      </Box>

      <Flex align-items="center" margin-inline-start="-10">
        <Flex
          align-items="center"
          as-child
          :border-radius="30"
          :class="styles.close"
          cursor="pointer"
          :padding="20"
        >
          <ToastClose aria-label="Close notification" @click="close(id)">
            <Icon aria-hidden :icon="X" :size="18" />
          </ToastClose>
        </Flex>
      </Flex>
    </Flex>
  </ToastRoot>
</template>
