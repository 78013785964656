<script setup lang="ts">
import { type VNode } from 'vue'
import { RouterLink, type RouterLinkProps } from 'vue-router'

import { Text, type TextProps } from '@/components'

export interface LinkProps extends RouterLinkProps, TextProps {}

export interface LinkSlots {
  default(props: { isActive: boolean; isExactActive: boolean }): Array<VNode>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { to, custom, replace, viewTransition, ...rest } = defineProps<LinkProps>()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const slots = defineSlots<LinkSlots>()

defineOptions({
  inheritAttrs: false
})
</script>

<template>
  <router-link
    v-slot="{ href, navigate, isActive, isExactActive }"
    v-bind="{ to, custom, replace }"
    custom
  >
    <Text v-bind="rest" as-child>
      <a
        :aria-current="isExactActive ? 'page' : false"
        v-bind="$attrs"
        :href="href"
        @click="navigate"
      >
        <slot :is-active="isActive" :is-exact-active="isExactActive" />
      </a>
    </Text>
  </router-link>
</template>
