import { computed, ref } from 'vue'

import { AxiosError } from 'axios'

const hasConflict = ref(false)
const displayOverlay = ref(false)

export function useConcurrentEdit() {
  function handleConflictError(error: AxiosError) {
    // display the conflict modal, in case the API responded with a 409 status code
    if (error?.response?.status === 409) {
      hasConflict.value = true
      displayOverlay.value = true
    }
  }

  function resolveConflict() {
    hasConflict.value = false

    // if the conflict was resolved, we can close the overlay
    // this usually happens when update request has finished successfully
    displayOverlay.value = false
  }

  function closeConflictOverlay() {
    displayOverlay.value = false
  }

  function getConflictHeader() {
    if (!hasConflict.value) {
      return {}
    }

    return {
      'x-emt-overwrite': hasConflict.value
    }
  }

  return {
    closeConflictOverlay,
    displayConflictOverlay: computed(() => displayOverlay.value),

    // we want to make `hasConflict` read-only to make sure it isn't altered without
    // using one of the methods provided by this composition function
    hasConflict: computed(() => hasConflict.value),
    getConflictHeader,
    handleConflictError,
    resolveConflict
  }
}
