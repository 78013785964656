<script setup lang="ts">
import { Primitive, type PrimitiveProps } from 'reka-ui'

import { sprinkles, type Sprinkles } from '@/styles/sprinkles.css'

export interface BoxProps extends PrimitiveProps {
  /** Aligns the box within its container. */

  alignSelf?: Sprinkles['alignSelf']

  /** Background color of the box. */

  backgroundColor?: Sprinkles['backgroundColor']

  /** Border color of the box. Usage in combination with `borderWidth` and `borderStyle`. */

  borderColor?: Sprinkles['borderColor']

  /** Border radius of the box.  */

  borderRadius?: Sprinkles['borderRadius']

  /** Border style of the box. Usage in combination with `borderColor`, `borderWidth` and `borderColor`.  */

  borderStyle?: Sprinkles['borderStyle']

  /** Border width of the box. Usage in combination with `borderColor`, `borderWidth` and `borderStyle`. */

  borderWidth?: Sprinkles['borderWidth']

  /** Box shadow. */

  boxShadow?: Sprinkles['boxShadow']

  /** Text color of the box. */

  color?: Sprinkles['color']

  /** Cursor of the box. */

  cursor?: Sprinkles['cursor']

  /** Display of the box. */

  display?: Sprinkles['display']

  /** Flex grow of the box. */

  flexGrow?: Sprinkles['flexGrow']

  /** Flex shrink of the box. */

  flexShrink?: Sprinkles['flexShrink']

  /** Grid column end of the box. Grid columns are defined by `LayoutGrid` */

  gridColumnEnd?: Sprinkles['gridColumnEnd']

  /** Grid column start of the box. Grid columns are defined by `LayoutGrid` */

  gridColumnStart?: Sprinkles['gridColumnStart']

  /** Font size of the box. */

  fontSize?: Sprinkles['fontSize']

  /** Height of the box. */

  height?: Sprinkles['height']

  /** Line height of the box. */

  lineHeight?: Sprinkles['lineHeight']

  /** Margin of the box. */

  margin?: Sprinkles['margin']

  /** Block margin of the box. */

  marginBlock?: Sprinkles['marginBlock']

  /** Block end margin of the box. */

  marginBlockEnd?: Sprinkles['marginBlockEnd']

  /** Block start margin of the box. */

  marginBlockStart?: Sprinkles['marginBlockStart']

  /** Inline margin of the box. */

  marginInline?: Sprinkles['marginInline']

  /** Inline end margin of the box. */

  marginInlineEnd?: Sprinkles['marginInlineEnd']

  /** Inline start margin of the box. */

  marginInlineStart?: Sprinkles['marginInlineStart']

  /** Maximum width of the box. */

  maxWidth?: Sprinkles['maxWidth']

  /** Minimum height of the box. */

  minHeight?: Sprinkles['minHeight']

  /** Minimum width of the box. */

  minWidth?: Sprinkles['minWidth']

  /** Outline of the box. */

  outline?: Sprinkles['outline']

  /** Overflow of the box. */

  overflow?: Sprinkles['overflow']

  /** Padding of the box. */

  padding?: Sprinkles['padding']

  /** Block padding of the box. */

  paddingBlock?: Sprinkles['paddingBlock']

  /** Block end padding of the box. */

  paddingBlockEnd?: Sprinkles['paddingBlockEnd']

  /** Block start padding of the box. */

  paddingBlockStart?: Sprinkles['paddingBlockStart']

  /** Inline padding of the box. */

  paddingInline?: Sprinkles['paddingInline']

  /** Inline end padding of the box. */

  paddingInlineEnd?: Sprinkles['paddingInlineEnd']

  /** Inline start padding of the box. */

  paddingInlineStart?: Sprinkles['paddingInlineStart']

  /** Position of the box. */

  position?: Sprinkles['position']

  /** Pointer events of the box. */

  pointerEvents?: Sprinkles['pointerEvents']

  /** User select */

  userSelect?: Sprinkles['userSelect']

  /* Vertical alignment of the boxs' content */

  verticalAlign?: Sprinkles['verticalAlign']

  /** Width of the box. */

  width?: Sprinkles['width']

  /** Z-index of the box. */

  zIndex?: Sprinkles['zIndex']
}

const { as, asChild, ...styleProps } = defineProps<BoxProps>()
</script>

<template>
  <Primitive :as="as" :as-child="asChild" :class="sprinkles(styleProps)">
    <slot />
  </Primitive>
</template>
