import Provider from './Provider.vue'
import Root from './Root.vue'

export const Notification = {
  Root,
  Provider
} as {
  Root: typeof Root
  Provider: typeof Provider
}

export { type NotificationProps } from './Notification.vue'
