<script setup lang="ts">
import { useHead } from '@unhead/vue'

import { Flex, Footer, Notification } from '@/components'

import '@/styles/theme.css'

const currentYear = new Date().getFullYear()

const FOOTER_LINKS = [
  {
    label: 'eea Website',
    to: 'https://www.european-energy-award.org'
  },
  {
    label: 'Contact',
    to: '/contact'
  },
  {
    label: 'Legal Information',
    to: '/imprint'
  },

  {
    label: 'Privacy Statement',
    to: '/privacy'
  }
]

const FOOTER_COPYRIGHT = [
  `© ${currentYear} Association European Energy Award`,
  'All information is confidential'
]

useHead({
  titleTemplate: '%s - eea Management Tool'
})
</script>

<template>
  <Notification.Provider>
    <Flex background-color="tertiary75" direction="column" min-height="full-dvh">
      <router-view />
    </Flex>

    <Footer :links="FOOTER_LINKS" :copyright="FOOTER_COPYRIGHT" />

    <Notification.Root />
  </Notification.Provider>
</template>

<style>
@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  src: url('@/assets/SourceSans3.woff2') format('woff2-variations');
}

@font-face {
  font-display: swap;
  font-family: 'Outfit';
  src: url('@/assets/Outfit.woff2') format('woff2-variations');
}
</style>
