<script setup lang="ts">
import Notification from './Notification.vue'

import { injectStrict } from '@/lib/injectStrict'
import { Notifications } from '@/lib/symbols'

const notifications = injectStrict(Notifications)
</script>

<template>
  <Notification
    v-for="notification in notifications"
    :key="notification.title"
    v-bind="notification"
  />
</template>
