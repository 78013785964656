import { type NotificationProps } from '@/components'

import { injectStrict } from '@/lib/injectStrict'
import { Notifications } from '@/lib/symbols'

let count = 0

export function useNotifications() {
  const notifications = injectStrict(Notifications)

  function notify(payload: Omit<NotificationProps, 'id'>) {
    const notification = {
      id: `notification-${count++}`,
      ...payload
    }

    // error notifications are persistent by default
    if (payload.variant === 'negative' && !payload.duration) {
      notification.duration = Infinity
    }

    notifications.value.push(notification)
  }

  function close(id: NotificationProps['id']) {
    const notificationIndex = notifications.value.findIndex(
      (notification) => notification.id === id
    )

    if (notificationIndex !== -1) {
      notifications.value = [
        ...notifications.value.slice(0, notificationIndex),
        {
          ...notifications.value[notificationIndex],
          open: false
        },
        ...notifications.value.slice(notificationIndex + 1)
      ]
    }
  }

  return {
    close,
    notify
  }
}
