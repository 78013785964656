export const languages = [
  {
    key: 'bg-BG',
    ietf: 'bg',
    label: 'ългарски (BG)'
  },
  {
    key: 'de-AT',
    ietf: 'de',
    label: 'Deutsch (AT)'
  },
  {
    key: 'de-CH',
    ietf: 'de',
    label: 'Deutsch (CH)'
  },
  {
    key: 'de-DE',
    ietf: 'de',
    label: 'Deutsch (DE)'
  },
  {
    key: 'de-LU',
    ietf: 'de',
    label: 'Deutsch (LU)'
  },
  {
    key: 'de-IT',
    ietf: 'de',
    label: 'Deutsch (IT)'
  },
  {
    key: 'el-GR',
    ietf: 'el',
    label: 'Ελληνικά (GR)'
  },
  {
    key: 'en',
    ietf: 'en',
    label: 'English'
  },
  {
    key: 'fr-BE',
    ietf: 'fr',
    label: 'Français (BE)'
  },
  {
    key: 'fr-CH',
    ietf: 'fr',
    label: 'Français (CH)'
  },
  {
    key: 'fr-FR',
    ietf: 'fr',
    label: 'Français (FR)'
  },
  {
    key: 'fr-LU',
    ietf: 'fr',
    label: 'Français (LU)'
  },
  {
    key: 'fr-MC',
    ietf: 'fr',
    label: 'Français (MC)'
  },
  {
    key: 'hr-HR',
    ietf: 'hr',
    label: 'Hrvatski (HR)'
  },
  {
    key: 'it-CH',
    ietf: 'it',
    label: 'Italiano (CH)'
  },
  {
    key: 'it-IT',
    ietf: 'it',
    label: 'Italiano (IT)'
  },
  {
    key: 'mk-MK',
    ietf: 'mk',
    label: 'Македонски (MK)'
  },
  {
    key: 'nl-BE',
    ietf: 'nl',
    label: 'Nederlandse (BE)'
  },
  {
    key: 'pl-PL',
    ietf: 'pl',
    label: 'Polski (PL)'
  },
  {
    key: 'sl-SI',
    ietf: 'sl',
    label: 'Slovenščina (SL)'
  },
  {
    key: 'sq-AL',
    ietf: 'sq',
    label: 'Shqip (SQ)'
  },
  {
    key: 'uk-UA',
    ietf: 'uk',
    label: 'Українська (UA)'
  }
] as const
