export const routes = [
  {
    path: '',
    name: 'welcome',
    component: () => import('@/pages/Welcome/+Page.vue')
  },

  {
    path: '/forgot-password',
    component: () => import('@/pages/ForgotPassword/+Page.vue')
  },

  {
    path: '/set-password',
    component: () => import('@/pages/SetPassword/+Page.vue')
  },

  {
    path: '/activate',
    component: () => import('@/pages/Activate/+Page.vue')
  },

  {
    path: '/contact',
    component: () => import('@/pages/Contact/+Page.vue')
  },

  {
    path: '/privacy',
    component: () => import('@/pages/Privacy/+Page.vue')
  },

  {
    path: '/imprint',
    component: () => import('@/pages/Imprint/+Page.vue')
  },

  {
    path: '/app',
    component: () => import('@/pages/App/+Layout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/pages/App/+Page.vue')
      },

      {
        path: '/logout',
        name: 'logout',
        component: () => import('@/pages/App/Logout/+Page.vue')
      },

      {
        path: 'user',
        children: [
          {
            path: 'edit',
            component: () => import('@/pages/App/User/Edit/+Page.vue')
          },

          {
            path: 'change-email',
            component: () => import('@/pages/App/User/ChangeEmail/+Page.vue')
          },

          {
            path: 'change-email-doi',
            component: () => import('@/pages/App/User/ChangeEmailDoi/+Page.vue')
          },

          {
            path: 'change-password',
            component: () => import('@/pages/App/User/ChangePassword/+Page.vue')
          },

          {
            path: 'log',
            component: () => import('@/pages/App/User/Log/+Page.vue')
          }
        ]
      },

      {
        path: ':branchSlug+',
        component: () => import('@/pages/App/:BranchSlug/+Layout.vue'),
        children: [
          {
            path: '',
            component: () => import('@/pages/App/:BranchSlug/+Page.vue')
          },

          {
            path: 'create-municipality',
            component: () => import('@/pages/App/:BranchSlug/CreateMunicipality/+Page.vue')
          },

          {
            path: 'edit',
            component: () => import('@/pages/App/:BranchSlug/Edit/+Page.vue')
          },

          {
            path: 'create',
            component: () => import('@/pages/App/:BranchSlug/Create/+Page.vue')
          },

          {
            path: 'log',
            component: () => import('@/pages/App/:BranchSlug/Log/+Page.vue')
          },

          {
            path: 'catalogs',
            children: [
              {
                path: '',
                component: () => import('@/pages/App/:BranchSlug/Catalogs/+Page.vue')
              },

              {
                path: ':catalogId(\\d+)',
                component: () => import('@/pages/App/:BranchSlug/Catalogs/:CatalogId/+Layout.vue'),
                children: [
                  {
                    path: 'approve',
                    component: () =>
                      import('@/pages/App/:BranchSlug/Catalogs/:CatalogId/Approve/+Page.vue')
                  },
                  {
                    path: 'assessment-guidances',
                    children: [
                      {
                        path: 'add',
                        component: () =>
                          import(
                            '@/pages/App/:BranchSlug/Catalogs/:CatalogId/AssessmentGuidances/Add/+Page.vue'
                          )
                      },

                      {
                        path: ':assessmentGuidanceId(\\d+)',
                        component: () =>
                          import(
                            '@/pages/App/:BranchSlug/Catalogs/:CatalogId/AssessmentGuidances/:AssessmentGuidanceId/+Layout.vue'
                          ),
                        children: [
                          {
                            path: 'clone',
                            component: () =>
                              import(
                                '@/pages/App/:BranchSlug/Catalogs/:CatalogId/AssessmentGuidances/:AssessmentGuidanceId/Clone/+Page.vue'
                              )
                          },

                          {
                            path: 'translations',
                            children: [
                              {
                                path: ':assessmentGuidanceTranslationId(\\d+)',
                                component: () =>
                                  import(
                                    '@/pages/App/:BranchSlug/Catalogs/:CatalogId/AssessmentGuidances/:AssessmentGuidanceId/Translations/:AssessmentGuidanceTranslationId/+Layout.vue'
                                  ),
                                children: [
                                  {
                                    path: '',
                                    component: () =>
                                      import(
                                        '@/pages/App/:BranchSlug/Catalogs/:CatalogId/AssessmentGuidances/:AssessmentGuidanceId/Translations/:AssessmentGuidanceTranslationId/+Page.vue'
                                      )
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },

                  {
                    path: 'clone',
                    component: () =>
                      import('@/pages/App/:BranchSlug/Catalogs/:CatalogId/Clone/+Page.vue')
                  },

                  {
                    path: 'translations',
                    children: [
                      {
                        path: ':catalogTranslationId(\\d+)',
                        component: () =>
                          import(
                            '@/pages/App/:BranchSlug/Catalogs/:CatalogId/Translations/:CatalogTranslationId/+Layout.vue'
                          ),
                        children: [
                          {
                            path: '',
                            component: () =>
                              import(
                                '@/pages/App/:BranchSlug/Catalogs/:CatalogId/Translations/:CatalogTranslationId/+Page.vue'
                              )
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },

          {
            path: 'users',
            children: [
              {
                path: '',
                component: () => import('@/pages/App/:BranchSlug/Users/+Page.vue')
              },

              {
                path: ':userId(\\d+)',
                component: () => import('@/pages/App/:BranchSlug/Users/:UserId/+Layout.vue'),
                children: [
                  {
                    path: '',
                    component: () => import('@/pages/App/:BranchSlug/Users/:UserId/+Page.vue')
                  },
                  {
                    path: 'log',
                    component: () => import('@/pages/App/:BranchSlug/Users/:UserId/Log/+Page.vue')
                  }
                ]
              },

              {
                path: 'add',
                component: () => import('@/pages/App/:BranchSlug/Users/Add/+Page.vue')
              }
            ]
          },

          {
            path: 'files',
            component: () => import('@/pages/App/:BranchSlug/Files/+Page.vue')
          },

          {
            path: 'municipality',
            children: [
              {
                path: ':municipalitySlug',
                component: () =>
                  import('@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/+Layout.vue'),
                children: [
                  {
                    path: '',
                    component: () =>
                      import('@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/+Page.vue')
                  },

                  {
                    path: 'cycles',
                    children: [
                      {
                        path: ':cycleId(\\d+)',
                        component: () =>
                          import(
                            '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Cycles/:CycleId/+Layout.vue'
                          ),
                        children: [
                          {
                            path: '',
                            component: () =>
                              import(
                                '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Cycles/:CycleId/AssessmentTool/+Layout.vue'
                              ),
                            children: [
                              {
                                path: '',
                                component: () =>
                                  import(
                                    '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Cycles/:CycleId/AssessmentTool/+Page.vue'
                                  )
                              },

                              {
                                path: 'reports',
                                component: () =>
                                  import(
                                    '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Cycles/:CycleId/AssessmentTool/Reports/+Page.vue'
                                  )
                              },

                              {
                                path: 'charts',
                                component: () =>
                                  import(
                                    '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Cycles/:CycleId/AssessmentTool/Charts/+Page.vue'
                                  )
                              },

                              {
                                path: 'files',
                                component: () =>
                                  import(
                                    '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Cycles/:CycleId/AssessmentTool/Files/+Page.vue'
                                  )
                              }
                            ]
                          },

                          {
                            path: ':assessmentId(\\d+)',
                            component: () =>
                              import(
                                '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Cycles/:CycleId/:AssessmentId/+Layout.vue'
                              ),
                            children: [
                              {
                                path: '',
                                component: () =>
                                  import(
                                    '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Cycles/:CycleId/:AssessmentId/+Page.vue'
                                  )
                              }
                            ]
                          },

                          {
                            path: 'edit',
                            component: () =>
                              import(
                                '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Cycles/:CycleId/Edit/+Page.vue'
                              )
                          }
                        ]
                      },

                      {
                        path: 'start',
                        component: () =>
                          import(
                            '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Cycles/Start/+Page.vue'
                          )
                      }
                    ]
                  },

                  {
                    path: 'edit',
                    component: () =>
                      import(
                        '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Edit/+Page.vue'
                      )
                  },

                  {
                    path: 'files',
                    component: () =>
                      import(
                        '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Files/+Page.vue'
                      )
                  },

                  {
                    path: 'log',
                    component: () =>
                      import('@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Log/+Page.vue')
                  },

                  {
                    // We can not use `users` here, because vue-router confuses it with the branch user
                    // endpoint, which I believe is a bug.
                    path: 'user',
                    children: [
                      {
                        path: ':userId(\\d+)',
                        component: () =>
                          import(
                            '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Users/:UserId/+Layout.vue'
                          ),
                        children: [
                          {
                            path: '',
                            component: () =>
                              import(
                                '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Users/:UserId/+Page.vue'
                              )
                          },

                          {
                            path: 'log',
                            component: () =>
                              import(
                                '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Users/:UserId/Log/+Page.vue'
                              )
                          }
                        ]
                      },

                      {
                        path: 'add',
                        component: () =>
                          import(
                            '@/pages/App/:BranchSlug/Municipality/:MunicipalitySlug/Users/Add/+Page.vue'
                          )
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },

      {
        path: ':pathMatch(.*)*',
        component: () => import('@/pages/App/NotFound/+Page.vue')
      }
    ]
  },

  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/pages/NotFound/+Page.vue')
  }
]
